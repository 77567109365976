import React from "react";
import { graphql } from "gatsby";

import Layout from '../components/layout'
import PostGrid from "../components/postgrid";

interface IAuthorPageProps {
    data: {
        ghostAuthor: IGhostAuthor,
        allGhostPost: {
            posts: {
                post: IGhostPost
            }[]
        }
    }
}

export default function Author({ data }: IAuthorPageProps) {
    const posts = data.allGhostPost.posts;

    return (
        <Layout>
            <p>Articles by</p>
            <h1 className="text-4xl font-bold mb-8">{data.ghostAuthor.name}</h1>
            <PostGrid posts={posts} featureTopPost={false} />
        </Layout>
    )
}

export const authorQuery = graphql`
    query GhostAuthorQuery {
        ghostAuthor(slug: {eq: "harrison"}) {
            bio
            website
            facebook
            twitter
            name
            location
        }
        allGhostPost(
            filter: {authors: {elemMatch: {slug: {eq: "harrison"}}}}
            sort: {order: DESC, fields: published_at}
        ) {
            posts: edges {
                post: node {
                    id
                    title
                    slug
                    excerpt
                    published_at(formatString: "MMMM DD, YYYY")
                    feature_image
                    primary_author {
                        name
                        slug
                    }
                    tags {
                        name
                        slug
                        visibility
                    }
                }
            }
        }
    }
`